import React from "react";
import MiniCategory from "./MniCategory";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import ReviewSummary from "./ReviewSummary";

const Description = () => {

    const check = <FontAwesomeIcon icon={faCheck} className="check-sign"/>;

    return ( 
        <div className="description">

            <div className="main-description-box">
                <div className="description-box-header">
                    <Link to="/sciencefiction" className="des-link active-des">
                        Description
                    </Link>
                

                    <Link to="/sciencefictionreview" className="des-link">
                        Reviews
                    </Link>
                </div>


                <div className="description-texts">
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod 
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim 
                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate 
                    velit esse cillum dolore eu fugiat nulla pariatur.  Lorem ipsum dolor 
                    sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                    Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse 
                    cillum dolore eu fugiat nulla pariatur. 
                    </p>


                    <MiniCategory />


                    <li>
                        {check}

                        Some great feature name here
                    </li>

                    <li>
                        {check}

                        Lorem ipsum dolor sit amet, consectetur 
                    </li>

                    <li>
                        {check}

                        Duis aute irure dolor in reprehenderit
                    </li>

                    <li>
                        {check}

                        Some great feature name here
                    </li>

                </div>
            </div>

            <ReviewSummary />

        </div>
     );
}
 
export default Description;