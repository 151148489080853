
import bookimg from '../images/bok.png'


const Journalbook = () => {

    return ( 
       
        <div className="bookline">
          <div className="eachbook">
            <img src={bookimg} alt="" />
            <div className="second-book">
              <h3>Journal Of Nigerian Row</h3>
              <p>Manie Johnas</p>
              <h3>2022</h3> 
              
            </div>
          </div>
          <div className="eachbook">
            <img src={bookimg} alt="" />
            <div className="second-book">
              <h3>Journal Of Nigerian Row</h3>
              <p>Manie Johnas</p>
              <h3>2022</h3> 
              
            </div>
          </div>
          <div className="eachbook">
            <img src={bookimg} alt="" />
            <div className="second-book">
              <h3>Journal Of Nigerian Row</h3>
              <p>Manie Johnas</p>
              <h3>2022</h3> 
              
            </div>
          </div>
          <div className="eachbook">
            <img src={bookimg} alt="" />
            <div className="second-book">
              <h3>Journal Of Nigerian Row</h3>
              <p>Manie Johnas</p>
              <h3>2022</h3> 
              
            </div>
          </div>
     
          </div>

         
        
     );
}
 
export default Journalbook;