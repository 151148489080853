import React from 'react'
import logo from '../images/logo.svg';
import {Link} from 'react-router-dom';

const Header = () => {
  return (
    <section className="heading-gen">
      <div className='heading'><img src={logo} alt="" /> <h3>warmbooks</h3></div>
      <div className="headlinks">
        <Link to="/" className='headlink'>Home</Link>
        <Link to="/books" className='headlink'>Books</Link>
        <Link to="/auth" className='headlink'>Journals</Link>
        <Link to="/" className='headlink'>Articles</Link>
      </div>
      <Link to="/" className="signin">Sign in</Link>

    </section>
  )
}

export default Header