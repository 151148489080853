import React from "react";
import { Link } from "react-router-dom";
import ReviewSummary from "./ReviewSummary";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'


const Review = () => {


    const star =<FontAwesomeIcon icon={faStar} className="star" /> ;

    return ( 
        <div className="description">

            <div className="main-description-box">
                <div className="description-box-header">
                    <Link to="/sciencefiction" className="des-link ">
                        Description
                    </Link>
                

                    <Link to="/sciencefictionreview" className="des-link active-des">
                        Reviews
                    </Link>
                </div>


                <div className="description-texts">

                    <div className="mega-review-box">

                        <div className="mini-review-box">
                            <div className="mini-review-img">

                            </div>

                            <h2>
                                Idorenyin Thomas
                            </h2>

                            <p>
                                January 19th, 2023
                            </p>

                            <div className="rating">
                                {star}
                                {star}
                                {star}
                                {star}
                                {star}

                                
                            </div> 
                        </div>

                        <p className="descript-review-texts">
                            Lorem ipsum dolor sit amet consectetur. Vestibulum 
                            fringilla porttitor vitae laoreet elit eu sollicitudin 
                            scelerisque egestas. Sagittis vel urna mi egestas. 
                            Adipiscing diam volutpat arcu nibh id cras. Sed varius 
                            tellus neque pharetra tristique pellentesque. Aliquam 
                            fermentum 
                        </p>
                    </div>
                    

                    <div className="mega-review-box">

                        <div className="mini-review-box">
                            <div className="mini-review-img">

                            </div>

                            <h2>
                                Idorenyin Thomas
                            </h2>

                            <p>
                                January 19th, 2023
                            </p>

                            <div className="rating">
                                {star}
                                {star}
                                {star}
                                {star}
                                {star}

                                
                            </div> 
                        </div>

                        <p className="descript-review-texts">
                            Lorem ipsum dolor sit amet consectetur. Vestibulum 
                            fringilla porttitor vitae laoreet elit eu sollicitudin 
                            scelerisque egestas. Sagittis vel urna mi egestas. 
                            Adipiscing diam volutpat arcu nibh id cras. Sed varius 
                            tellus neque pharetra tristique pellentesque. Aliquam 
                            fermentum 
                        </p>
                    </div>

                    <div className="mega-review-box">

                        <div className="mini-review-box">
                            <div className="mini-review-img">

                            </div>

                            <h2>
                                Idorenyin Thomas
                            </h2>

                            <p>
                                January 19th, 2023
                            </p>

                            <div className="rating">
                                {star}
                                {star}
                                {star}
                                {star}
                                {star}

                                
                            </div> 

                        </div>

                        <p className="descript-review-texts">
                            Lorem ipsum dolor sit amet consectetur. Vestibulum 
                            fringilla porttitor vitae laoreet elit eu sollicitudin 
                            scelerisque egestas. Sagittis vel urna mi egestas. 
                            Adipiscing diam volutpat arcu nibh id cras. Sed varius 
                            tellus neque pharetra tristique pellentesque. Aliquam 
                            fermentum 
                        </p>
                    </div>

                    <div className="mega-review-box">

                        <div className="mini-review-box">
                            <div className="mini-review-img">

                            </div>

                            <h2>
                                Idorenyin Thomas
                            </h2>

                            <p>
                                January 19th, 2023
                            </p>

                            <div className="rating">
                                {star}
                                {star}
                                {star}
                                {star}
                                {star}

                                
                            </div> 
                            
                        </div>

                        <p className="descript-review-texts">
                            Lorem ipsum dolor sit amet consectetur. Vestibulum 
                            fringilla porttitor vitae laoreet elit eu sollicitudin 
                            scelerisque egestas. Sagittis vel urna mi egestas. 
                            Adipiscing diam volutpat arcu nibh id cras. Sed varius 
                            tellus neque pharetra tristique pellentesque. Aliquam 
                            fermentum 
                        </p>


                        <div className="more-view">
                            <p>
                                More Review
                            </p>

                            <button className="page-number">
                                &lt;
                            </button>

                            <button className="page-number">
                                1
                            </button>

                            <button className="page-number">
                                2
                            </button>

                            <button className="page-number">
                                3
                            </button>

                            <button className="page-number">
                                4
                            </button>

                            <button className="page-number">
                                &gt;
                            </button>
                        </div>
                    </div>
                    

                   

                </div>


            </div>

            <ReviewSummary />

        </div>
     );
}
 
export default Review;